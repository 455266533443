import React from "react";
import { Typography, Link } from '@mui/material'

const Footer = (props) => {
    return(
        <>
            <Typography variant="body2" className="footer-text" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="/">
                    SA Gaming Back Office
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </>
    )
}

export default Footer