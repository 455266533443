import React, { useState, useRef } from "react";
import { Avatar, Container, Typography, Box, Button, CssBaseline, TextField  } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReCAPTCHA from "react-google-recaptcha"
import Footer from "../../components/Footer";
import { isLogged, setUserSession } from "../../utils";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import Loading from "../../components/Loading";
import logo from '../../images/sa.png'

const theme = createTheme();

const Login = () => {
    const recaptchaRef = useRef();
    const session = isLogged()
    const [open, setOpen] = useState(false)
    const [Login, setLogin] = useState('Login')
    const [state, setState] = useState({
        username: '',
        password: '',
    })
    const { username, password } = state;
    const handleInputChange = (e) => {
        let { id, value } = e.target
        setState({ ...state, [id]: value })
    }

    const handleCaptcha = () => {}

    const handleSubmit = () => {
        setOpen(true)
        setLogin('Loading...')
        const recaptchaValue = recaptchaRef.current.getValue();

        if(username === "" || password === "" || recaptchaValue === "") {
            if(username === "") {
                setOpen(false)
                setLogin('Login')
                Toastify({
                    text: `Username is required field.`,
                    className: 'toast-error',
                    position: "right"
                }).showToast()
            }
            
            if(password === "") {
                setOpen(false)
                setLogin('Login')
                Toastify({
                    text: `Password is required field.`,
                    className: 'toast-error',
                    position: "right"
                }).showToast()
            }

            if(recaptchaValue === "") {
                setOpen(false)
                setLogin('Login')
                Toastify({
                    text: `ReCaptcha is required field.`,
                    className: 'toast-error',
                    position: "right"
                }).showToast()
            }
        }
        else {
            if(username !== process.env.REACT_APP_USER || password !== process.env.REACT_APP_PASS) {
                if(username !== process.env.REACT_APP_USER) {
                    setOpen(false)
                    setLogin('Login')
                    Toastify({
                        text: `The username you entered is incorrect.`,
                        className: 'toast-error',
                        position: "right"
                    }).showToast()
                }

                if(password !== process.env.REACT_APP_PASS) {
                    setOpen(false)
                    setLogin('Login')
                    Toastify({
                        text: `The password you entered is incorrect.`,
                        className: 'toast-error',
                        position: "right"
                    }).showToast()
                }
            }
            else {
                setTimeout(() => {
                    setUserSession(username)
                    window.location.href = '/sa-gaming'
                }, 2000)
            }
        }
    }

    if(session === null) {
        return(
            <>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            className="background-login"
                        >
                            <Avatar sx={{ m: 1 }} className="lock-icon">
                                <LockOutlinedIcon />
                            </Avatar>
                            <img src={logo} className="images-logo"/>
                            <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
                                Back Office
                            </Typography>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    value={username}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={handleInputChange}
                                    sx={{ mb: 2 }}
                                />
                                <ReCAPTCHA 
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_SITEKEY}
                                    onChange={handleCaptcha}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    className="btn-login"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={handleSubmit}
                                    disabled={open}
                                >
                                    {Login}
                                </Button>
                            </Box>
                        </Box>
                        <Footer sx={{ mt: 6, mb: 4 }} />
                    </Container>
                    <Loading open={open}/>
                    {/* <div style={{ position: "fixed", top: "100px", right: "0px", backgroundColor: "red", width: "180px", height: "50px" }}>
                        <p style={{ paddingTop: "13px", paddingLeft: "15px", color: "#fff", fontSize: "15px", }}>UAT Environment</p>
                    </div> */}
                </ThemeProvider>
            </>
        )
    }
    else {
        setTimeout(() => {
            window.location.href = "/sa-gaming"
        }, 2000)
        return(
            <Loading open={true} />
        )
    }
}

export default Login