import React, { useState } from "react";
import { 
    Table, 
    TableContainer, 
    TablePagination, 
    TableHead, 
    TableBody, 
    TableRow, 
    TableCell, 
    Button,
    Grid,
    DialogContent,
    Typography,
    Dialog
} from "@mui/material";
import { CSVLink } from "react-csv";

const TableData = (props) => {
    const [fileName, setFileName] = useState('')
    const [modal, setModal] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    let arrData = []
    let sumTotalPC1 = 0
    let sumTotalPC2 = 0
    let sumTotalPC3 = 0
    let sumTotalPC4 = 0
    let sumTotalJW1 = 0
    let sumTotalJW2 = 0
    let sumTotalJW3 = 0
    let sumTotalJW4 = 0
    let sumTotalBet = 0
    let sumTotalPayout = 0
    
    let disabledBtn = ''

    if(props.data.length === 0) {
        disabledBtn = 'disabled'
    }
    else {
        disabledBtn = ''
        for(let x=0; x<props.data.length; x++) {
            let AccType = '';
            if(props.data[x]['AccountType'] === 0) {
                AccType = 'LandBased'
            }
            else {
                AccType = 'RGP'
            }

            arrData[x] = {
                GameDate: props.data[x]['GameDate'],
                GameProvider: props.data[x]['GameProvider'],
                GameName: props.data[x]['GameName'],
                GameId: props.data[x]['GameId'],
                AccountName: props.data[x]['AccountName'],
                OutletCode: props.data[x]['OutletCode'],
                AccountType: AccType,
                PC1: props.data[x]['PC1'],
                PC2: props.data[x]['PC2'],
                PC3: props.data[x]['PC3'],
                PC4: props.data[x]['PC4'],
                JW1: props.data[x]['JW1'],
                JW2: props.data[x]['JW2'],
                JW3: props.data[x]['JW3'],
                JW4: props.data[x]['JW4'],
                TotalBet: props.data[x]['TotalBet'],
                TotalPayout: props.data[x]['TotalPayout'],
            }
    
            sumTotalPC1 += props.data[x]['PC1']
            sumTotalPC2 += props.data[x]['PC2']
            sumTotalPC3 += props.data[x]['PC3']
            sumTotalPC4 += props.data[x]['PC4']
            sumTotalJW1 += props.data[x]['JW1']
            sumTotalJW2 += props.data[x]['JW2']
            sumTotalJW3 += props.data[x]['JW3']
            sumTotalJW4 += props.data[x]['JW4']
            sumTotalBet += props.data[x]['TotalBet']
            sumTotalPayout += props.data[x]['TotalPayout']
        }
    }
    
    let TotalPC1 = Math.round(sumTotalPC1*100)/100
    let TotalPC2 = Math.round(sumTotalPC2*100)/100
    let TotalPC3 = Math.round(sumTotalPC3*100)/100
    let TotalPC4 = Math.round(sumTotalPC4*100)/100

    let TotalJW1 = Math.round(sumTotalJW1*100)/100
    let TotalJW2 = Math.round(sumTotalJW2*100)/100
    let TotalJW3 = Math.round(sumTotalJW3*100)/100
    let TotalJW4 = Math.round(sumTotalJW4*100)/100

    let TotalBet = Math.round(sumTotalBet*100)/100
    let TotalPayout = Math.round(sumTotalPayout*100)/100

    const downloadFile = () => {
        setModal(true)
        let d = new Date()
        let splitDate = d.toISOString().split('T')
        let date2 = splitDate[0].split('-')
        let time2 = splitDate[1].split('.')
        let t = time2[0].split(':')
        let filename = `sagaming${date2[0]}${date2[1]}${date2[2]}${t[0]}${t[1]}${t[2]}.csv`
        setFileName(filename)
    }

    const handleCloseCsv = () => {
        setModal(false)
    }

    return(
        <>
            <TableContainer>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        sx={{ m:1, align: "right"}} 
                        onClick={downloadFile}
                        disabled={disabledBtn}
                    >
                        Download File
                    </Button>
                </Grid>
                
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Game Date</TableCell>
                            <TableCell>Game Provider</TableCell>
                            <TableCell>Game Name</TableCell>
                            <TableCell>Game ID</TableCell>
                            <TableCell>Account Name</TableCell>
                            <TableCell>Outlet Code</TableCell>
                            <TableCell>Account Type</TableCell>
                            <TableCell>Total Bet</TableCell>
                            <TableCell>Total Payout</TableCell>
                            <TableCell>PC1</TableCell>
                            <TableCell>PC2</TableCell>
                            <TableCell>PC3</TableCell>
                            <TableCell>PC4</TableCell>
                            <TableCell>JW1</TableCell>
                            <TableCell>JW2</TableCell>
                            <TableCell>JW3</TableCell>
                            <TableCell>JW4</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.data.length === 0
                            ?
                                <>
                                    <TableRow>
                                        <TableCell colSpan={17} align="center">{'No available in table.'}</TableCell>
                                    </TableRow>
                                </>
                            :
                            props.data.length === 0
                            ?
                                <>
                                    <TableRow>
                                        <TableCell colSpan={17} align="center">{props.data.responseStatus}</TableCell>
                                    </TableRow>
                                </>
                            :
                            arrData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((res, index) => {
                                let date = res['GameDate'].split('T')
                                let time = date[1].split('.')
                                return(
                                    <>
                                        <TableRow key={index}>
                                            <TableCell>{`${date[0]} ${time[0]}`}</TableCell>
                                            <TableCell>{res['GameProvider']}</TableCell>
                                            <TableCell>{res['GameName']}</TableCell>
                                            <TableCell>{res['GameId']}</TableCell>
                                            <TableCell>{res['AccountName']}</TableCell>
                                            <TableCell>{res['OutletCode']}</TableCell>
                                            <TableCell>{res['AccountType']}</TableCell>
                                            <TableCell>{res['TotalBet']}</TableCell>
                                            <TableCell>{res['TotalPayout']}</TableCell>
                                            <TableCell>{res['PC1']}</TableCell>
                                            <TableCell>{res['PC2']}</TableCell>
                                            <TableCell>{res['PC3']}</TableCell>
                                            <TableCell>{res['PC4']}</TableCell>
                                            <TableCell>{res['JW1']}</TableCell>
                                            <TableCell>{res['JW2']}</TableCell>
                                            <TableCell>{res['JW3']}</TableCell>
                                            <TableCell>{res['JW4']}</TableCell>
                                        </TableRow>
                                    </>
                                )
                            })
                        }
                        <TableRow>
                            <TableCell colSpan={7} align="right">Totals:</TableCell>
                            <TableCell>{TotalBet.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalPayout.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalPC1.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalPC2.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalPC3.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalPC4.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalJW1.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalJW2.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalJW3.toLocaleString("en-US")}</TableCell>
                            <TableCell>{TotalJW4.toLocaleString("en-US")}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <TablePagination 
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={props.data.length === 0 ? 0 : arrData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={modal}
            >
                <Typography align="center" variant="subtitle1" sx={{pt:5, pb:3}}>File has been download successfully!</Typography>
                <DialogContent>
                    <CSVLink data={arrData} filename={fileName}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button variant="contained" onClick={handleCloseCsv}>OK</Button>
                        </Grid>
                    </CSVLink>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default TableData